import React from "react";

const cardsData = [
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-letsoffer-img.jpeg",
    title: "Let's Offer",
    description:
      "Let's Offer allows you to manage all your offers to potential customers in one place. You can create an offer in two minutes! Just fill in the basic information using the form, and your offer will generate itself on a pre-designed template. You can send the link to the offer to the potential customer in any form.",
    link: "https://letsoffer.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-okularyexpert-img.jpeg",
    title: "Okulary Expert",
    description:
      "The project included the design and coding of a graphical interface, which was implemented into a PHP-based CMS. The online store integrates the payment system Przelewy24 and the ERP used in the stationary salon.",
    link: "https://okulary.expert",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-contractorflow-img.jpeg",
    title: "ContractorFlow",
    description:
      "Our ContractorFlow web application, developed for sales representatives and small businesses, allows you to manage your contractors from one place. In addition, the CRM integrates with the BIR1.1 system, allowing you to retrieve company information based on TIN from the CSO database.",
    link: "https://contractorflow.pl",
    category: "CRM",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-kasiatlumaczy-img.jpeg",
    title: "Kasia Tłumaczy",
    description:
      "A Python project with Django that automates the translation of PDF files. The application processes the files and returns them in English, using the OpenAI model for more accurate results than Google Translate.",
    link: "",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-flexae-img.jpeg",
    title: "Flex Smart System",
    description:
      "A website for a technology company in the Emirates, made in TailwindCSS and integrated with a PHP-based CMS. Contact forms are linked to the CRM software, enabling management of requests in one place.",
    link: "https://flex.ae",
    category: "Strona internetowa + CRM",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-sofollow-img.jpeg",
    title: "SoFollow",
    description:
      "The project included the creation of an online store with virtual products. The store integrates with ING bank and Stripe payment system, and uses geolocation to set the appropriate currency.",
    link: "https://sofollow.eu",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-complexhome-img.jpeg",
    title: "Complex Home",
    description:
      "Complex Home's online store has a custom-designed interface, which ensures higher performance and no technical limitations. The interface has been integrated with the e-commerce platform and the systems of Przelewy24 and InPost Paczkomat.",
    link: "https://complexhome.eu",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-jakleci-img.jpeg",
    title: "Jak Leci",
    description:
      "Jak-Leci is an online store supporting travel organization. The project included a comprehensive UI/UX, CMS implementation and integration with an invoicing system via REST API. The store offers a variety of options and a price calculator.",
    link: "https://jak-leci.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-artoni-img.jpeg",
    title: "Artoni",
    description:
      "We prepared the interface design for Artoni Foundation and comprehensively implemented it into the content management system. This resulted in high performance and ease of content management from the administration panel.",
    link: "",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-managly-img.jpeg",
    title: "Managly",
    description:
      "Managly application is a software for freelancers.The project includes a landing page and a web application with a back-end in Python Django and a front-end created in HTML/CSS and JavaScript.",
    link: "https://managly.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-gosiabednarek-img.jpeg",
    title: "Bednarek Studio",
    description:
      "A website with virtual services and products.The project included designing and coding the GUI and implementing it into WordPress WooCommerce.",
    link: "https://gosiabednarek.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-mtawedding-img.jpeg",
    title: "MTA Wedding",
    description:
      "For MTA Wedding, we refreshed the GUI and improved SEO metrics.We designed the UI/UX interface and then implemented it in HTML/CSS/JS and PHP, which improved Web Core Vitals scores by dozens of points.",
    link: "https://mtawedding.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-apteczkanaszlaku-img.jpeg",
    title: "Apteczka na szlaku",
    description: "",
    link: "https://apteczkanaszlaku.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-katasze-img.jpeg",
    title: "Katasze",
    description:
      "The project created a graphical interface and implemented it into an open source PHP-based CMS.The online store integrates the payment system Przelewy24 via API.",
    link: "https://katasze.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-trafficpolska-img.jpeg",
    title: "Traffic Polska",
    description:
      "The project for Traffic Poland included the design and implementation of a website, which was implemented into a CMS that allows the site to be managed from an administration panel.",
    link: "https://trafficpolska.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-boatman-img.jpeg",
    title: "Boatman",
    description:
      "For the Boatman online store, we created a new GUI, programmed from scratch and implemented into the existing store on the WooCommerce platform.",
    link: "https://boatman.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-naturecoaching-img.jpeg",
    title: "Nature Coaching Institute",
    description: "",
    link: "https://naturecoachinginstitute.eu/",
    category: "Strona itnernetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-porownajsolary-img.jpeg",
    title: "Porównaj Solary",
    description:
      "Compare Solary is a web application that uses back-end technologies, such as Python Django, and front-end technologies with an interface created in NextJS. This framework provides high performance and efficient positioning.",
    link: "https://porownajsolary.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-marinaolchowiec-img.jpeg",
    title: "Marina Olchowiec",
    description: "",
    link: "https://marina-olchowiec.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-epojazd-img.jpeg",
    title: "E-Pojazd",
    description:
      "As part of the implementation, a GUI design was created and comprehensively implemented into an open source PHP-based CMS using WYSIWYG tools.",
    link: "https://e-pojazd.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-carboneperformance-img.jpeg",
    title: "Carbone Performance",
    description:
      "Online store based on WooCommerce, integrated with ERP system and Przelewy24. With this solution, the store has an external warehouse linked to accounting and courier systems.",
    link: "",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-basiaolearka-img.jpeg",
    title: "Basia Olearka",
    description: "",
    link: "https://basiaolearka.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-redsky-img.jpeg",
    title: "Red Sky",
    description:
      "We created a weblog for Red Sky, designing the interface in accordance with their corporate identity.We did the programming of the views and implemented them into WordPress.",
    link: "https://red-sky.com/blog",
    category: "Blog",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-revopack-img.jpeg",
    title: "Revopack",
    description: "",
    link: "https://revopack.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-mkczesci-img.jpeg",
    title: "MK Części",
    description:
      "MK Parts, a chain of online auto parts stores, is based on WordPress with WooCommerce.The site contains a database of nearly one million products.",
    link: "https://mkczesci.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-wpolszczi-img.jpeg",
    title: "wPolszczi",
    description: "",
    link: "https://wpolszczi.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-vitellolimited-img.jpeg",
    title: "Vitello Limited",
    description: "",
    link: "https://vitellolimited.com/",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-dietetycznecudawianki-img.jpeg",
    title: "Dietetyczne Cudawianki",
    description: "",
    link: "https://dietetycznecudawianki.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-apartamentyhappy-img.jpeg",
    title: "Apartamenty Happy",
    description: "",
    link: "https://apartamentyhappy.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-petartclub-img.jpeg",
    title: "PetArt Club",
    description: "",
    link: "https://petartclub.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-stayintorrevieja-img.jpeg",
    title: "Stay in Torrevieja",
    description: "",
    link: "https://stayintorrevieja.com",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-bosoboso-img.jpeg",
    title: "BosoBoso",
    description: "",
    link: "https://bosoboso.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-panoramasystem-img.jpeg",
    title: "Panorama System",
    description: "",
    link: "https://panoramasystem.eu",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-kancelariabbj-img.jpeg",
    title: "Kancelaria BBJ",
    description: "",
    link: "https://kancelariabbj.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-moneda-img.jpeg",
    title: "Moneda",
    description: "",
    link: "https://moneda.com.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-gemini-img.jpeg",
    title: "Gemini Rzeszów",
    description: "",
    link: "https://geminirzeszow.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-netservis-img.jpeg",
    title: "Netservis",
    description: "",
    link: "https://netservis.com.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-heritage-img.jpeg",
    title: "Heritage2000",
    description: "",
    link: "",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-villanette-img.jpeg",
    title: "Villanette",
    description: "",
    link: "https://villanette.pl/",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-eloco-img.jpeg",
    title: "Eloco",
    description: "",
    link: "https://eloco.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-akso-img.jpeg",
    title: "AKSO",
    description: "",
    link: "https://akso.com.pl/",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-carbonedetailing-img.jpeg",
    title: "Carbone Detailing",
    description: "",
    link: "https://carbone-detailing.com",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-opelmkczesci-img.jpeg",
    title: "Opel MK Części",
    description: "",
    link: "https://opelmkczesci.pl",
    category: "E-commerce",
  },
];

function Portfolio() {
  return (
    <section className="blackColorBg px-6 lg:px-24 pt-12 grid grid-cols-1 lg:grid-cols-5 gap-6 lg:gap-12 mb-6 lg:mb-24">
      {cardsData.map((card, index) => (
        <div
          key={index}
          className={`${
            index % 4 === 0 || index % 4 === 3
              ? "lg:col-span-3"
              : "lg:col-span-2"
          } h-[400px] group overflow-hidden rounded-xl relative hidden lg:flex flex-col items-center justify-center`}
        >
          <img
            src={card.img}
            alt={card.title}
            className="w-full h-full object-cover z-10 group-hover:scale-110 transition duration-300 ease-linear"
          />
          <div className="w-full h-full bg-gradient-to-t from-black/80 to-transparent absolute z-20 opacity-100 group-hover:opacity-0 transition duration-300 ease-linear"></div>
          <div className="w-full h-full opacity-0 group-hover:opacity-90 bg-black transition duration-300 ease-linear absolute z-20"></div>
          <p className="z-30 absolute bottom-10 left-10 text-4xl text-white font-semibold group-hover:opacity-0 transition duration-100 ease-linear">
            {card.title}
          </p>
          <div className="z-30 absolute bottom-10 left-10 text-base text-white group-hover:opacity-100 transition duration-100 ease-linear opacity-0 max-w-[80%]">
            {card.description && <p className="mb-6">{card.description}</p>}
            {card.link && (
              <a target="_blank" href={card.link}>
                <button className="bg-violet-600 px-6 py-1 text-white">
                  Check out
                </button>
              </a>
            )}
          </div>
          {/* <p className="px-12 py-1 text-center text-white absolute top-10 left-10 mixedGradients z-40 rounded-xl">
            {card.category}
          </p> */}
        </div>
      ))}
      {cardsData.map((card, index) => (
        <a
          key={index}
          className="h-[400px] group overflow-hidden rounded-xl relative lg:hidden flex flex-col items-center justify-center"
          href={card.link}
        >
          <img
            src={card.img}
            alt={card.title}
            className="w-full h-full object-cover z-10 group-hover:scale-110 transition duration-300 ease-linear"
          />
          <p className="z-30 absolute bottom-10 left-10 text-4xl text-white font-semibold group-hover:opacity-0 transition duration-100 ease-linear">
            {card.title}
          </p>
          <p className="px-12 py-1 text-center text-white absolute top-10 left-10 mixedGradients z-40 rounded-xl">
            {card.category}
          </p>
        </a>
      ))}
    </section>
  );
}

export default Portfolio;
