import React, { useRef } from "react";
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
  wrap,
} from "framer-motion";

function Brands() {
  const marqueeVariants = {
    animate: {
      x: [0, -500],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 5,
          ease: "linear",
        },
      },
    },
  };

  const marqueeVariants2 = {
    animate: {
      x: [-500, 0],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 5,
          ease: "linear",
        },
      },
    },
  };

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1.6]);

  const cardBackgroundStyle1 = {
    backgroundImage:
      "url(https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/brands/brand-ifil.png)",
  };

  const cardBackgroundStyle2 = {
    backgroundImage:
      "url(https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/brands/brand-letsoffer.png)",
  };

  const cardBackgroundStyle3 = {
    backgroundImage:
      "url(https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/contractorflow-pl/fk-contractorflow-img.webp)",
  };

  return (
    <section id="brands" className="flex flex-col py-12 bg-zinc-950">
      <div className="py-12 relative flex flex-col items-center justify-center">
        <h2 className="text-8xl text-white text-center absolute z-50">
          My Brands
        </h2>
        <div>
          <motion.div
            className="track"
            variants={marqueeVariants}
            animate="animate"
          >
            <span>
              Contractor flow iFil Group Managly Porównaj Solary Contractor flow
              iFil Group Managly Porównaj Solary Contractor flow iFil Group
              Managly Porównaj Solary Contractor flow iFil Group Managly
              Porównaj Solary Contractor flow iFil Group Managly Porównaj Solary
              Contractor flow iFil Group Managly Porównaj Solary Contractor flow
              iFil Group Managly Porównaj Solary Contractor flow iFil Group
              Managly Porównaj Solary Contractor flow iFil Group Managly
              Porównaj Solary Contractor flow iFil Group Managly Porównaj Solary
              Contractor flow iFil Group Managly Porównaj Solary Contractor flow
              iFil Group Managly Porównaj Solary Contractor flow iFil Group
              Managly Porównaj Solary Contractor flow iFil Group Managly
              Porównaj Solary
            </span>
          </motion.div>
          <motion.div
            className="track"
            variants={marqueeVariants2}
            animate="animate"
          >
            <span>
              Contractor flow Managly Porównaj Solary iFil Group Contractor flow
              Managly Porównaj Solary iFil Group Contractor flow Managly
              Porównaj Solary iFil Group Contractor flow Managly Porównaj Solary
              iFil Group Contractor flow Managly Porównaj Solary iFil Group
              Contractor flow Managly Porównaj Solary iFil Group Contractor flow
              Managly Porównaj Solary iFil Group Contractor flow Managly
              Porównaj Solary iFil Group Contractor flow Managly Porównaj Solary
              iFil Group Contractor flow Managly Porównaj Solary iFil Group
              Contractor flow Managly Porównaj Solary iFil Group Contractor flow
              Managly Porównaj Solary iFil Group
            </span>
          </motion.div>
          <motion.div
            className="track"
            variants={marqueeVariants}
            animate="animate"
          >
            <span>
              Managly Porównaj Solary Contractor flow iFil Group Managly
              Porównaj Solary Contractor flow iFil Group Managly Porównaj Solary
              Contractor flow iFil Group Managly Porównaj Solary Contractor flow
              iFil Group Managly Porównaj Solary Contractor flow iFil Group
              Managly Porównaj Solary Contractor flow iFil Group Managly
              Porównaj Solary Contractor flow iFil Group Managly Porównaj Solary
              Contractor flow iFil Group Managly Porównaj Solary Contractor flow
              iFil Group Managly Porównaj Solary Contractor flow iFil Group
            </span>
          </motion.div>
        </div>
      </div>
      <motion.div
        style={{
          scale,
        }}
      >
        <div className="flex flex-col lg:flex-row w-full items-center lg:justify-center overflow-x-hidden py-4 px-24">
          <div className="card-container">
            <div className="card">
              <a href="https://ifil.pl">
                <div
                  className="card-background"
                  style={cardBackgroundStyle1}
                ></div>
                <h3 className="card-heading">iFil</h3>
              </a>
            </div>
            <div className="card">
              <a href="https://letsoffer.pl">
                <div
                  className="card-background"
                  style={cardBackgroundStyle2}
                ></div>
                <h3 className="card-heading">Let's Offer</h3>
              </a>
            </div>
            <div className="card">
              <a href="https://contractorflow.pl">
                <div
                  className="card-background"
                  style={cardBackgroundStyle3}
                ></div>
                <h3 className="card-heading">Contractor Flow</h3>
              </a>
            </div>
          </div>
        </div>
        <motion.div
          style={{
            scaleY: scrollYProgress,
          }}
        />
      </motion.div>
    </section>
  );
}

export default Brands;
